import React from 'react';
import styled from 'styled-components';

import buttonUrl from '../../assets/images/photo_border.svg';

const StyledForm = styled.form`
  display: grid;
  grid-template:
    'feedback feedback'
    'email submit'
    / auto min-content;
  gap: 1rem;
  margin-top: -2rem;

  @media (max-width: ${props => props.theme.breakSmall}) {
    gap: 0.5rem;
    margin-top: -0.5rem;
  }

  * {
    ${props => props.theme.customTextStyle};
  }
`;

const StyledTextarea = styled.textarea`
  grid-area: feedback;
  display: block;
  width: 100%;
  padding: 1rem 1rem 0.7rem;
  border: 2px solid ${props => props.theme.black};
  border-radius: 3px;
  resize: none;
  font-size: 1.125rem;
  letter-spacing: 0;
  text-transform: initial;

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 0.5rem 0.5rem 0.35rem;
  }
`;

const StyledLabel = styled.label`
  grid-area: email;
  display: flex;
  align-items: center;
`;

const StyledEmailLabel = styled.span`
  font-size: 0.875rem;

  span {
    font-size: 0.75rem;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: none;
  }
`;

const StyledEmailInput = styled.input`
  flex-grow: 1;
  border: 2px solid ${props => props.theme.black};
  border-radius: 3px;
  margin-left: 0.8rem;
  padding: 0.5rem 0.8rem 0.3rem;

  &::placeholder {
    opacity: 0;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin: 0;

    &::placeholder {
      opacity: 1;
    }
  }
`;

const StyledBorder = styled.div`
  display: inline-block;
  ${props => props.theme.borderMask(buttonUrl)};
`;

const StyledSubmit = styled.input`
  width: 100%;
  padding: 0.5rem 3rem 0.2rem;
  border: 0;
  background: ${props => props.theme.black};
  color: ${props => props.theme.white};
  font-size: 0.875rem;
  text-align: center;

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 0.5rem 2rem 0.2rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    padding: 0.5rem 0.5rem 0.2rem;
  }
`;

const FeedbackForm: React.FC = () => {
  return (
    <StyledForm name="feedback-form" method="POST" data-netlify="true">
      <input type="hidden" name="form-name" value="feedback-form" />
      <StyledTextarea name="feedback" rows={5} required></StyledTextarea>
      <StyledLabel>
        <StyledEmailLabel>
          Email&nbsp;<span>[optional]:</span>
        </StyledEmailLabel>
        <StyledEmailInput
          type="email"
          name="email"
          placeholder="Email [optional]"
        />
      </StyledLabel>
      <StyledBorder>
        <StyledSubmit type="submit" value="Submit" />
      </StyledBorder>
    </StyledForm>
  );
};

export default FeedbackForm;
