import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../layouts/Layout';
import SEO from '../components/SEO';
import useRandomBgColor from '../hooks/useRandomBgColor';
import AboutContent from '../components/about/AboutContent';

interface AboutPageProps extends PageProps {
  data: {
    file: {
      childMdx: {
        id: string;
        frontmatter: {
          title: string;
          top_paragraph: string;
          bottom_paragraph: string;
          pages: {
            page: string;
            link: string;
            description: string;
          }[];
        };
      };
    };
  };
}

const AboutPage: React.FC<AboutPageProps> = function (props) {
  const {
    data: {
      file: {
        childMdx: { frontmatter },
      },
    },
    location,
  } = props;

  useRandomBgColor();

  return (
    <Layout currPage={location.pathname}>
      <SEO title="About | Courtney Barnett" />
      <AboutContent content={frontmatter} />
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query aboutQuery {
    file(sourceInstanceName: { eq: "about" }) {
      childMdx {
        id
        slug
        frontmatter {
          title
          top_paragraph
          bottom_paragraph
          pages {
            description
            link
            page
          }
          embed
        }
      }
    }
  }
`;
