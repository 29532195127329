import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import styled from 'styled-components';

import borderUrl from '../../assets/images/text_border.svg';
import mobileBorderUrl from '../../assets/images/about_mobile.svg';
import { ReactComponent as Tape } from '../../assets/images/text_tape.svg';
import { ReactComponent as Underline } from '../../assets/images/nav_underline.svg';
import FeedbackForm from './FeedbackForm';

const StyledContainer = styled.div`
  position: relative;
  margin: 3rem auto;
  width: 90vw;
  max-width: 65rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin-top: calc(2rem + 50px);
  }

  @media (max-width: ${props => props.theme.Small}) {
    margin-top: calc(1rem + 50px);
  }
`;

const StyledBorder = styled.div`
  width: 100%;
  ${props => props.theme.borderMask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    ${props => props.theme.borderMask(mobileBorderUrl)};
  }
`;

const StyledContent = styled.div`
  width: 100%;
  padding: 5rem 3.5rem;
  background: ${props => props.theme.white};
  ${props => props.theme.mask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 4rem 2.5rem;
    ${props => props.theme.mask(mobileBorderUrl)};
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    padding: 3rem 1.2rem;
  }
`;

const StyledTitle = styled.h1`
  margin-bottom: 1.5rem;
  ${props => props.theme.customTextStyle};
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: initial;

  @media (max-width: ${props => props.theme.breakMedium}) {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
`;

const StyledBody = styled.div`
  display: inline-block;
  margin-bottom: 2.5rem;
  ${props => props.theme.customTextStyle};
  font-size: 1.125rem;
  letter-spacing: 0;
  text-transform: initial;
  overflow-wrap: break-word;
  hyphens: auto;

  p {
    display: inline-block;
    font-size: 1.125rem;
    letter-spacing: 0;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    font-size: 0.875rem;
    margin-bottom: 2rem;

    p {
      font-size: 0.875rem;
    }
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    margin-bottom: 1rem;
  }
`;

const StyledPageItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 4rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: block;
  }
`;

const StyledPageItem = styled.div``;

const StyledPageTitle = styled(Link)`
  position: relative;
  margin-bottom: 0.5rem;
  padding-bottom: 0.2rem;
  color: ${props => props.theme.black};
  ${props => props.theme.customTextStyle};
  font-size: 1.125rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    font-size: 1rem;
  }
`;

const StyledUnderline = styled(Underline)`
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  bottom: 4px;
  fill: black;
`;

const StyledTape = styled(Tape)`
  position: absolute;
  top: -1.4rem;
  left: 50%;
  width: 10rem;
  transform: translateX(-50%);
  stroke: ${props => props.theme.black};
  stroke-width: 10;

  @media (max-width: ${props => props.theme.breakMedium}) {
    top: -1.2rem;
    width: 8rem;
    stroke-width: 12;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    top: -0.8rem;
    width: 6rem;
    stroke-width: 16;
  }
`;

const AboutContent: React.FC = props => {
  const {
    content: { title, top_paragraph, bottom_paragraph, pages },
  } = props;

  return (
    <StyledContainer>
      <StyledBorder>
        <StyledContent>
          <StyledTitle>{title}</StyledTitle>
          <StyledBody>
            <MDXRenderer>{top_paragraph}</MDXRenderer>
          </StyledBody>
          <StyledPageItems>
            {pages.map(page => (
              <StyledPageItem key={page.link}>
                <StyledPageTitle to={page.link}>
                  {page.page} <StyledUnderline />
                </StyledPageTitle>
                <StyledBody>{page.description}</StyledBody>
              </StyledPageItem>
            ))}
          </StyledPageItems>
          <StyledBody>
            <MDXRenderer>{bottom_paragraph}</MDXRenderer>
          </StyledBody>
          <FeedbackForm />
        </StyledContent>
      </StyledBorder>
      <StyledTape />
    </StyledContainer>
  );
};

export default AboutContent;
